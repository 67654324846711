footer {
    width: 100vw;
    height: 30vh;
    background-color: $color_dark_grey;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    position: sticky;
    top: 0;


    h2 {
        color: $color_white;
        font-weight: bold;
        font-size: 30px;
    }

    img {
        width: 20%;
        cursor: pointer;
    }

}
