@media screen and (max-width: 800px) {
    .mouse {
        display: none;
    }

    .container-home {
        h1 {
            font-size: 100px;
        }

        .wrapper-img {

            &.a, &.b, &.c {
                display: none;
            }
        }
    }

    .container-types-sensors {

        flex-direction: column;

        .cam {
            width: 100vw;
            height: 50vh;
            border-bottom: 2px solid $color_white;

        }

        .yt {
            width: 100vw;
            height: 50vh;
            border-top: 2px solid $color_white;
            margin-left: -2px;

        }
    }

    .container-safe-beach {

        flex-direction: column;
        .wrapper {
            .btn-download {
                margin-left: 25vw;
            }
        }

        img {
            width: 40%;
        }
    }

    .container-types-sensors {
        img {
            width: 60%;
        }
    }
}
