.mouse {
    position: fixed;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $color_black;
    transition: .1s;
}




