.container-zone {
    width: 100vw;
    height: 100vh;
    display: flex;

  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  button {
    border: 2px solid $color_black;
    border-radius: 2px;
    width: 200px;
    height: 50px;
    background-color: $color_white;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
    z-index: 1;
    position: relative;

    &:hover {
      background-color: $color_black;
      color: $color_white;
    }
  }

  .target {
    border: 2px solid $color_black;
    width: 50px;
    height: 50px;
    position: absolute;
  }

}