.container-home {
    width: 100vw;
    height: 100vh;
    background-color: $color_dark_grey;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        font-size: 200px;
        text-align: center;
        color: $color_white;
        animation: animation-launch;
        animation-duration: 2s;

        img {
            transform: scale(1.2);
        }
    }

    h2 {
        color: $color_white;
        font-size: 20px;
        animation: animation-launch;
        animation-duration: 2s;
    }

    .btn-download {
        margin-top: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 50px;
        border-radius: 50px;
        background-color: $color_white;
        border: 2px solid $color_white;
        cursor: pointer;
        transition: .5s;
        font-size: 16px;
        font-weight: bold;
        animation: animation-launch;
        animation-duration: 2s;

        &:hover {
            background-color: $color_dark_grey;
            border: 2px solid $color_white;
            box-shadow: 1px 10px 20px #111;
            color: $color_white;
        }
    }

    .wrapper-img {
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        animation: animation-launch;
        animation-duration: 2s;

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            cursor: pointer;
            transition: .5s;

            &:hover {
                transform: scale(1.4);
            }
        }

        &.a {
            top: 55vh;
            left: 10vw;
        }

        &.b {
            top: 20vh;
            left: 80vw;
        }

        &.c {
            top: 80vh;
            left: 70vw;
        }

    }


}

.container-safe-beach {
    width: 100vw;
    height: 100vh;
    background: $color_white;
    overflow: hidden;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .wrapper {
        overflow: visible;

        h2 {
            color: $color_dark_grey;
            text-align: center;
            font-size: 20px;
        }

        .btn-download {
            margin-top: 10vh;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 50px;
            border-radius: 50px;
            background-color: $color_dark_grey;
            border: 2px solid $color_white;
            color: $color_white;
            cursor: pointer;
            transition: .5s;
            font-size: 16px;
            font-weight: bold;

            &:hover {
                transform: scale(1.2);
            }
        }

    }


    img {
        width: 25%;
        cursor: pointer;
        transform: rotateX(10deg) rotateY(-18deg) rotateZ(3deg);
        border-radius: 2px;
        box-shadow: 2px 10px 30px hsla(0, 0%, 0%, 0.25);
        transition: 0.5s;
        top: 15vh;
        left: 20vw;

        &:hover {
            transform: rotate(0);
        }
    }


}

.container-roboflow {
    width: 100vw;
    height: 100vh;
    background: $color_dark_grey;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    flex-direction: column;

    h2 {
        color: $color_white;
        text-align: center;

    }

    h3 {
        margin-left: 10%;
        color: $color_white;
        width: 80%;
        font-weight: normal;

        span {
            font-weight: bold;

            &.link {
                cursor: pointer;
                text-decoration: underline;
            }
        }

    }
}

.container-types-sensors {
    width: 100vw;
    height: 100vh;
    background-color: $color_dark_grey;
    display: flex;

    h3 {
        color: $color_white;
        font-size: 30px;
        font-weight: bold;
    }

    .cam {
        width: 50vw;
        height: 100vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;

        img {
            cursor: pointer;
            transition: .5s;
            transform: rotateX(10deg) rotateY(-18deg) rotateZ(3deg);

            &:hover {
                transform: rotate(0);
            }
        }
    }

    .yt {
        width: 50vw;
        height: 100vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;


        img {
            width: 80%;
            cursor: pointer;
            transform: rotateX(10deg) rotateY(-18deg) rotateZ(3deg);
            border-radius: 2px;
            box-shadow: 2px 10px 30px hsla(0, 0%, 0%, 0.25);
            transition: 0.5s;
            top: 15vh;
            left: 20vw;

            &:hover {
                transform: rotate(0);
            }
        }
    }
}

.container-join {
    width: 100vw;
    height: 100vh;
    background-color: $color_white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
        color: $color_black;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
    }

    .btn-join {
        margin-top: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        height: 50px;
        border-radius: 50px;
        background-color: $color_black;
        border: 2px solid $color_black;
        cursor: pointer;
        transition: .5s;
        font-size: 16px;
        font-weight: bold;
        color: $color_white;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.container-home-page {
    position: sticky;
    top: 0;
}

.container-alert {
    width: 100vw;
    height: 100vh;
    background: $color_white;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    flex-direction: column;


    h2 {
        color: $color_dark_grey;
        text-align: center;

    }

    h3 {
        margin-left: 10%;
        color: $color_dark_grey;
        width: 80%;
        font-weight: normal;

        span {
            font-weight: bold;

            &.link {
                cursor: pointer;
                text-decoration: underline;
            }
        }

    }


}
