@font-face {
  font-family: 'my-font';
  src: url("../assets/font.otf");
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: 'my-font', serif;
}

body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

$color_blue_dark: #30A2FF;
$color_blue_light: #00C4FF;
$color_blue_dark_dark: #4931d3;
$color_light_grey: #e8eeef;
$color_beige_dark: #FFE7A0;
$color_beige_light: #FFF5B8;
$color_black: #111;
$color_white: #eee;
$color_red: #ab0a0a;
$color_orange: #f17020;
$color_green: #397546;
$color_dark_grey: #23232d;
